export default {
  breakpoints: ['40em', '52em', '65em'], //["26em", "68em", "125em"], // mobile -> tablet, tablet -> desktop, desktop -> widescreen
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  fonts: {
    body:
      'Poppins, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
    heading: "inherit",
    monospace: "Menlo, monospace"
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 38, 44, 50, 58, 66, 72, 88],
  sizes: [
    "1rem",
    "2rem",
    "3rem",
    "4rem",
    "5rem",
    "6rem",
    "7rem",
    "8rem",
    "16rem",
    "32rem",
    "0.2rem",
    "40rem",
    "12rem",
    "0.5rem",
  ],
  fontWeights: {
    body: 400,
    heading: 800,
    lightbold: 600,
    bold: 800
  },
  lineHeights: {
    body: 1.5,
    heading: 1
  },
  colors: {
    text: "#000000",
    background: "#ffffff",
    primary: "#e20512",
    secondary: "#f04607",
    muted: "#F2F2F2",
    mutedTwo: "lightgrey"
  },
  // variants can use custom, user-defined names:
  text: {
    heading: {
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "lightbold"
    },
    body: {
      fontFamily: "body",
      lineHeight: "body",
      fontWeight: "body",
    },
    caps: {
      textTransform: "uppercase",
      letterSpacing: "0.1em"
    }
  },
  spacing: {
    mb: [5, 6, 6]
  },
  // variants for buttons
  buttons: {
    primary: {
      // you can reference other values defined in the theme
      color: "background",
      bg: "primary"
    },
    secondary: {
      color: "background",
      bg: "secondary"
    }
  },
  //Example styles
  styles: {
    root: {
      "@font-face": {
        fontDisplay: "swap",
      },
      fontFamily: "body",
      lineHeight: "body",
      fontWeight: "body"
    },
    h1: {
      variant: "text.heading",
      fontSize: 5
    },
    h2: {
      variant: "text.heading",
      fontSize: 4
    },
    h3: {
      variant: "text.heading",
      fontSize: 3
    },
    h4: {
      variant: "text.heading",
      fontSize: 2
    },
    h5: {
      variant: "text.heading",
      fontSize: 1
    },
    h6: {
      variant: "text.heading",
      fontSize: 0
    },
    a: {
      color: "text",
      fontSize: 4,
      textDecoration: "none",
      mx: 3,
      "&:hover": {
        color: "primary",
        textDecoration: "underline"
      }
    },
    pre: {
      fontFamily: "monospace",
      overflowX: "auto",
      code: {
        color: "inherit"
      }
    },
    code: {
      fontFamily: "monospace",
      fontSize: "inherit"
    },
    table: {
      width: "100%",
      borderCollapse: "separate",
      borderSpacing: 0
    },
    th: {
      textAlign: "left",
      borderBottomStyle: "solid"
    },
    td: {
      textAlign: "left",
      borderBottomStyle: "solid"
    }
  }
};
